import React, { useEffect, useMemo, useState } from 'react';

import {
  Container,
  SlideTitle,
  PropertyFeature,
  PropertyFeaturesContainer,
  PropertyInfoWrapper,
  Wrapper,
  Text,
  Image,
  TextWrapper,
} from './LuxuryCover.styles';
import bg from './bg.png';
import { useUser } from '../../../../../providers/UserProvider';
import { useSlide } from '../../../../../providers/SlideProvider';
import { CoverLogos } from '../components/CoverLogos/CoverLogos.component';
import { Report } from '../../../../../../../pages/PresentationCreate/dash';
import { useSelectedSlide } from '../../../../../../../providers/providers/SelectedSlideProvider';
import { useSlides } from '../../../../../../../providers/providers/SlidesProvider';
import { EditIcon } from '../components/EditIcon/EditIcon.component';
import { EditInput } from '../components/EditInput/EditInput.component';
import { PropertyFeatures } from './PropertyFeatures.component';
import { useIsEditAllowed } from '../hooks/useIsEditAllowed';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../../../../../../hooks/usePresentation';

export const LuxuryCoverRegular: React.FC = () => {
  const { address, addressShort, beds, size } = Report.data.subject.useSubjectInfo();
  const user = useUser();
  const isCanadaUser = user?.isCanadaUser;

  const isEditAllowed = useIsEditAllowed();

  const { selectedSlide, setSlideDataProperty } = useSelectedSlide()!;
  const { setSlide } = useSlides();

  const slide = useSlide();

  const { hash } = useParams<{ hash: string }>();

  const { data: presentation } = usePresentation(hash);

  const displayImage = slide?.data?.displayImage;
  const image = displayImage && slide?.data?.image ? slide?.data?.image : bg;

  const initialAddress = useMemo(() => {
    if (!addressShort) return '';

    return `${addressShort} ${
      displayImage ? `| ${address.city}, ${address.state} ${address.zip}` : ''
    }`;
  }, [address, addressShort, displayImage]);

  const predefinedTitle = useMemo(() => {
    if (initialAddress) return initialAddress;

    return presentation?.clientName || '';
  }, [initialAddress, presentation?.clientName]);

  const [isEditTitle, setIsEditTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(slide?.data?.text || predefinedTitle);

  const featuresText =
    !isNaN(parseInt(beds)) && size
      ? `${beds} Bedrooms ${!isCanadaUser ? `${size} SQFT Living Area` : ''}`
      : '';

  const [isEditFeatures, setIsEditFeatures] = useState(false);
  const [featuresValue, setFeaturesValue] = useState(slide?.data?.text2 || featuresText);

  useEffect(() => {
    setTitleValue(slide?.data?.text || predefinedTitle);
  }, [predefinedTitle, slide?.data?.text]);

  const onTitleChange = (val: string) => {
    setTitleValue(val);
  };

  const onFeaturesChange = (val: string) => {
    setFeaturesValue(val);
  };

  const onApplyTitleClick = () => {
    if (!selectedSlide) return;

    if (!titleValue) {
      setTitleValue(addressShort);
    }

    const updatedData = {
      ...selectedSlide.data,
      text: titleValue,
    };

    setSlide(selectedSlide.id, {
      ...selectedSlide,
      data: updatedData,
    });

    setSlideDataProperty('text', titleValue);
    setIsEditTitle(false);
  };

  const onApplyFeaturesClick = () => {
    if (!selectedSlide) return;

    if (!featuresValue) {
      setFeaturesValue(featuresText);
    }

    const updatedData = {
      ...selectedSlide.data,
      text2: featuresValue,
    };

    setSlide(selectedSlide.id, {
      ...selectedSlide,
      data: updatedData,
    });

    setSlideDataProperty('text2', featuresValue);
    setIsEditFeatures(false);
  };

  const slideTitle = useMemo(() => {
    if (presentation?.type === 'winTheListing' || presentation?.type === 'winTheOffer') {
      return 'PRESENTING';
    }
    return 'PRESENTATION PREPARED FOR';
  }, [presentation?.type]);

  const renderFeatures = slide?.data?.text2 ? (
    <PropertyFeaturesContainer displayImage={displayImage}>
      <PropertyFeature displayImage={displayImage}>{featuresValue}</PropertyFeature>
      {isEditAllowed && <EditIcon onClick={() => setIsEditFeatures(true)} />}
    </PropertyFeaturesContainer>
  ) : (
    <PropertyFeatures
      displayImage={displayImage}
      beds={beds}
      size={size}
      setIsEditFeatures={setIsEditFeatures}
    />
  );

  return (
    <Container>
      <Image src={image} />
      <Wrapper displayImage={displayImage}>
        <PropertyInfoWrapper>
          <Text displayImage={displayImage}>{slideTitle}</Text>
          {isEditTitle ? (
            <EditInput
              inputValue={titleValue}
              inputStyles={{ fontSize: displayImage ? 34 : 75, fontWeight: 700, width: '100%' }}
              onChange={onTitleChange}
              onApplyClick={onApplyTitleClick}
              width={displayImage ? '40%' : '90%'}
            />
          ) : (
            <TextWrapper>
              <SlideTitle displayImage={displayImage}>{titleValue}</SlideTitle>
              {isEditAllowed && <EditIcon onClick={() => setIsEditTitle(true)} />}
            </TextWrapper>
          )}
          {isEditFeatures ? (
            <EditInput
              inputValue={featuresValue}
              inputStyles={{ fontSize: displayImage ? 18 : 24, fontWeight: 400, width: '100%' }}
              onChange={onFeaturesChange}
              onApplyClick={onApplyFeaturesClick}
              width={displayImage ? '40%' : '70%'}
            />
          ) : (
            renderFeatures
          )}
        </PropertyInfoWrapper>
        <CoverLogos displayImage={displayImage} presentedByStyles={{ fontFamily: 'Cinzel' }} />
      </Wrapper>
    </Container>
  );
};
