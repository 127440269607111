import React from 'react';
import { useSelector } from 'react-redux';

import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';

import { sellingPaceData } from '../data';
import { useSellingPaceTookTimeMessage } from '../../../../../../../../pages/PresentationCreate/dash/ReportFacade/components/CompsPerformance/SellingPaceGauge/useSellingPaceTookTimeMessage';
import { SellingPaceChart } from '../../../../../../../../features/report/charts/selling-pace/SellingPaceChart';

const SellingPacePdf: React.FC = () => {
  const { category } = useCategory();
  const { heading, description } = sellingPaceData;

  const data = useSelector(
    (state: any) => state?.report?.reportDetailed?.graphs?.selling_pace ?? [],
  );

  const subtitle = useSellingPaceTookTimeMessage(data);

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={heading}
        title={data?.median_of_all || data?.average_of_all}
        subtitle={subtitle}
        description={description}
      >
        <SellingPaceChart style={{ height: 470 }} />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default SellingPacePdf;
