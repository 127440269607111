import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import React, { createRef, useEffect, useState } from 'react';

import * as configItems from './Configuration.data';
import { ConfigurationContainer } from './Configuration.styles';
import { ConfigurationItemComponent } from './ConfigurationItem.component';
import { corePresentApi } from '../../../../api/CorePresentApi';
import { AnalyticsModalEvents } from '../PastPresentation/components/AnalyticsModal/services/AnalyticsModalEvent';

function useLastPresentationSession() {
  return useQuery(['lastPresentationSession'], async () => {
    const lastSession = await corePresentApi.get('/sessions/last-session');

    return lastSession;
  });
}

export const ConfigurationComponent: React.FC = () => {
  const ref = createRef<HTMLDivElement>();
  const { push } = useHistory();

  const [sessionId, setSessionId] = useState(null);
  const [lastAction, setLastAction] = useState('Loading...');
  const [presentationHash, setPresentationHash] = useState(null);

  const { data: lastSession } = useLastPresentationSession();

  useEffect(() => {
    if (lastSession?.session_ended_at) {
      setLastAction(
        `${lastSession.client_name ?? 'Someone'} interacted with a presentation on ${
          lastSession.human_date_short
        }`,
      );
      setSessionId(lastSession.hash);
      setPresentationHash(lastSession.presentation_hash);
      return;
    }
    setLastAction('No interactions yet.');
  }, [lastSession]);

  const item3 = {
    ...configItems.item_3,
    subtitle: lastAction,
    url: '/home',
  };

  return (
    <ConfigurationContainer ref={ref}>
      <ConfigurationItemComponent {...configItems.item_1} onClick={push} />
      <ConfigurationItemComponent {...configItems.item_2} onClick={push} />
      <ConfigurationItemComponent
        {...item3}
        onClick={() => AnalyticsModalEvents.open(presentationHash)}
      />
      <ConfigurationItemComponent {...configItems.item_4} onClick={push} />
    </ConfigurationContainer>
  );
};
