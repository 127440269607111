import styled from 'styled-components';
import React, { useState } from 'react';

import {
  PastPresentationItemEvents,
  PastPresentationItemComponent,
} from './PastPresentationItem.component';
import { PastPresentationItem } from '../../../types';
import { Icon } from '../../../../../../../components/Common';

export interface PastPresentationGroupComponentProps extends PastPresentationItemEvents {
  items: PastPresentationItem[];
  isLoading?: string | number | boolean;
  analyticsOpen?: string;
  onAnalyticsOpen: (hash: string) => void;
}

interface WithOpen {
  open: number;
  itemcount: number;
}
const TRBody = styled('div')<WithOpen>(({ theme, open, itemcount }) => ({
  display: 'flex',
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 8,
  overflow: 'hidden',
  height: open ? itemcount * 77 : 77,
  transition: 'all .2s',
  minWidth: 1140,
  flexShrink: 0,
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

const TRBodyButton = styled('div')<{ isDisabled?: boolean }>(({ theme, isDisabled }) => ({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  width: 45,
  height: '100%',
  backgroundColor: theme.colors.gray[100],
  cursor: isDisabled ? 'default' : 'pointer',
  transition: 'all .2s',
  marginRight: 22.5,
  '&:hover': {
    backgroundColor: isDisabled ? theme.colors.gray[100] : theme.colors.gray[200],
  },
}));

const TRBodyContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const PastPresentationGroup: React.FC<PastPresentationGroupComponentProps> = ({
  items,
  children,
  ...eventProps
}) => {
  const [open, setOpen] = useState(false);

  const isDisabled = items.length < 2;

  const handleOpen = () => {
    if (isDisabled) return;
    setOpen(prev => !prev);
  };

  return (
    <TRBody open={open ? 1 : 0} itemcount={items.length}>
      <TRBodyButton onClick={handleOpen} isDisabled={isDisabled}>
        {!isDisabled ? <Icon iconName={open ? 'collapse_large' : 'expand_large'} /> : null}
      </TRBodyButton>
      <TRBodyContent>
        {items.map(item => {
          return (
            <PastPresentationItemComponent
              key={item.id}
              {...item}
              {...eventProps}
              versions={items.length}
              open={open}
            />
          );
        })}
      </TRBodyContent>
    </TRBody>
  );
};
