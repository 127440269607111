import React from 'react';
import { useSelector, useStore } from 'react-redux';

export const useSubjectInfo = () => {
  const {
    address = {},
    beds,
    baths,
    totalBaths,
    size,
    coordinates,
    propertyType,
  } = useSelector(({ report }) => report?.reportDetailed?.subject ?? {});

  return React.useMemo(
    () => ({
      addressShort: address?.deliveryLine,
      address,
      beds,
      baths,
      totalBaths,
      size,
      coordinates,
      propertyType,
    }),
    [address, beds, baths, totalBaths, size, coordinates, propertyType],
  );
};

export const useSubjectInfoGetter = () => {
  const store = useStore();

  return () => store.getState()?.report?.reportDetailed?.subject || {};
};
