import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';

import { PastPresentationItem } from './types';
import Loader from '../../../../components/Loader/Loader';
import { Container, Row, Input } from './PastPresentation.styles';
import { usePdfGenerationRetry } from './hooks/usePdfGenerationRetry';
import { PastPresentationsUtils } from './services/PastPresentationsUtils';
import { PresentationApi } from '../../../Presentation/api/PresentationApi';
import { PastPresentationTableComponent } from './components/Table/PastPresentationTable.component';
import { SendEmailPopup } from '../../../../components/Common/SendEmailPopup/SendEmailPopup.component';
import WarningConfirmPopup from '../../../../components/Common/WarningConfirmPopup/WarningConfirmPopup';
import { useLoadPresentationsWithPdfPooling } from './hooks/useLoadPresentations';
import { getPresentationTypeFromApi } from '../../../../services/getPresentationTypeFromApi';

export const PastPresentationComponent: React.FC = () => {
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState<string | boolean>(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isLoading, data } = useLoadPresentationsWithPdfPooling();
  const [presentationToMail, setPresentationToMail] = useState<PastPresentationItem>();

  const [presentations, setPresentations] = useState<PastPresentationItem[][]>([]);

  const [filteredPresentations, setFilteredPresentations] = useState<PastPresentationItem[][]>([]);

  const { mutate: pdfGenerationRetry, isLoading: isPdfGenerationLoading } = usePdfGenerationRetry();

  useEffect(() => {
    if (!data) return;

    setPresentations(PastPresentationsUtils.getTableRows(data, 'lastUpdated_dsc'));

    if (isPdfGenerationLoading) return;
    pdfGenerationRetry(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setFilteredPresentations(PastPresentationsUtils.filter(presentations, search));
  }, [presentations, search]);

  if (isLoading)
    return (
      <Container>
        <Loader size='3x' centered />
      </Container>
    );

  const onDeletePresentation = async () => {
    if (typeof confirm === 'boolean') return;
    try {
      setLoading(true);
      await PresentationApi.delete(confirm);
      setPresentations(PastPresentationsUtils.deleteOne(presentations, confirm));
      setConfirm(false);
    } catch (err) {
      console.error(err);
      toast('Something went wrong while deleting presentation', {
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (id: string) => {
    const copyText = inputRef.current;
    if (!copyText) return;
    const baseUrl = window.location.protocol + '//' + window.location.host;
    copyText.value = `${baseUrl}/present/presentation/${id}`;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    toast('Copied to clipboard', { type: 'info', position: 'bottom-center' });
  };

  const onMailClick = (hash: string) => {
    setOpen(true);
    const presentation = presentations.flat().find(dataItem => dataItem.hash === hash);
    setPresentationToMail(presentation);
  };

  return (
    <Container>
      <Row>
        <Input
          type='text'
          placeholder='Search'
          icon={{ iconName: 'search' }}
          search
          width={254}
          onChange={e => setSearch(e.target.value)}
        />
      </Row>
      <PastPresentationTableComponent
        presentations={filteredPresentations}
        onLinkClick={hash => copyToClipboard(hash)}
        onPdfClick={pdf => pdf && window.open(pdf)}
        onOpenClick={hash => {}}
        onMailClick={onMailClick}
        onRemoveClick={id => setConfirm(id)}
        setPresentations={setPresentations}
        onAnalyticsOpen={hash => {}}
      />
      {open && (
        <SendEmailPopup
          address={presentationToMail?.address}
          presentationType={getPresentationTypeFromApi(presentationToMail?.type || 'info')}
          isOpen={open}
          onClose={() => setOpen(false)}
          presentationHash={presentationToMail?.hash ?? ''}
          client={{
            id: presentationToMail?.clientId,
            email: presentationToMail?.clientEmail,
            name: presentationToMail?.clientName,
          }}
        />
      )}
      {!!confirm && (
        <WarningConfirmPopup
          open={!!confirm}
          heading='Delete this presentation?'
          description='The presentation data will be deleted!'
          isLoading={loading}
          onConfirm={onDeletePresentation}
          onCancel={() => setConfirm(false)}
          onClose={() => setConfirm(false)}
        />
      )}
      <input
        type='text'
        ref={inputRef}
        style={{ position: 'absolute', opacity: 0, zIndex: -1 }}
        value=''
        readOnly
      />
    </Container>
  );
};
