import React, { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Icon } from '../../../../MainSection/MainSection.styles';
import Loader from '../../../../../../../components/Loader/Loader';
import { TD, Typography } from '../PastPresentationTable.styles';
import { PastPresentationItem, TPresentationTypeApi } from '../../../types';
import { BUYER_TOUR } from '../../../../../../../features/BuyerTour/constants';
import { TPresentationMode, TPresentationType } from '../../../../../../../types';
import { PRESENTATION_TYPES } from '../../../../../../../constants/PresentationTypes';
import { Button } from '../../../../../../../components/Common/V2/Button/Button';
import { Button as TableButton } from '../../../components/PastPresentationsTable/components/Button';

const PresentationItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 77,
  flexShrink: 0,
}));

const IconWrapper = styled('div')(({ theme }) => ({
  flexShrink: 0,
  marginRight: theme.spacing(1.5),
  position: 'relative',
  width: 22,
}));

const ButtonWrapper = styled('div')({
  display: 'flex',
  gap: 8,
  height: '100%',
  alignItems: 'center',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const PresentationMode = styled(Typography)({
  textTransform: 'uppercase',
  fontSize: 12,
  lineHeight: 1.1,
});

export interface PastPresentationItemEvents {
  onRebuildClick?: (id: string, type: TPresentationType) => void;
  onLinkClick: (id: string) => void;
  onPdfClick: (id: string) => void;
  onOpenClick: (id: string) => void;
  onMailClick: (hash: string) => void;
  onRemoveClick: (id: string) => void;
  onAnalyticsOpen: (hash: string) => void;
}

type PastPresentationItemComponentProps = PastPresentationItem &
  PastPresentationItemEvents & {
    open?: boolean;
    buildInProgress?: boolean;
    pdf: string;
    analyticsOpen?: string;
    onAnalyticsOpen: (hash: string) => void;
  };

export const PastPresentationItemComponent: React.FC<PastPresentationItemComponentProps> = ({
  id,
  hash,
  address,
  clientName,
  lastUpdated,
  versions,
  type,
  open,
  buildInProgress = false,
  pdf,
  analyticsOpen,
  sessionsCount,
  mode,
  onRebuildClick,
  onLinkClick,
  onPdfClick,
  onMailClick,
  onRemoveClick,
  onOpenClick,
  onAnalyticsOpen,
  canBeRebuilt,
  topFeedback,
  topSellerBarrier,
}) => {
  const [rebuildInProgress, setRebuildInProgress] = useState(false);

  const onRebuild = React.useCallback(
    function onRebuild() {
      const typeToPresentationType = {
        seller: PRESENTATION_TYPES.WIN_THE_LISTING,
        buyer: PRESENTATION_TYPES.WIN_THE_OFFER,
        info: PRESENTATION_TYPES.PRESENT_INFO,
        [PRESENTATION_TYPES.BUYER_TOUR]: PRESENTATION_TYPES.BUYER_TOUR,
      };

      if (onRebuildClick) {
        onRebuildClick(hash, typeToPresentationType[type] as TPresentationType);
        setRebuildInProgress(true);
      }
    },
    [hash, onRebuildClick, type],
  );

  const getIconName = (type: TPresentationTypeApi) => {
    switch (type) {
      case 'seller':
        return 'trophy';
      case 'buyer':
        return 'offer';
      case 'info':
        return 'present';
      case BUYER_TOUR:
        return 'mapWithPinGray';
      default:
        return 'present';
    }
  };

  const getTitle = (address: string, type: TPresentationTypeApi) => {
    if (address === '') {
      switch (type) {
        case 'info':
          return 'Presentation Info';
        case BUYER_TOUR:
          return 'Buyer Tour';
        default:
          return address;
      }
    }

    return address;
  };

  const getModeLabel = (mode?: TPresentationMode) => {
    switch (mode) {
      case 'regular':
        return 'Presentation';
      case 'cma':
        return 'CMA';
      case 'oneSheeter':
        return 'Single Page Review';
      default:
        return 'Presentation';
    }
  };

  const iconName = getIconName(type);

  const mapWithPinIconStyles: CSSProperties = {
    width: 42,
    height: 42,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <PresentationItemContainer>
      <TD>
        {type && (
          <IconWrapper>
            <Icon
              iconName={iconName}
              fill={iconName !== 'mapWithPinGray' ? '#444' : 'none'}
              style={iconName === 'mapWithPinGray' ? mapWithPinIconStyles : {}}
            />
          </IconWrapper>
        )}
        <TitleWrapper>
          <Typography variant='body1' color='primary' onClick={f => f}>
            {getTitle(address, type)}
          </Typography>
          <PresentationMode color='300'>{getModeLabel(mode)}</PresentationMode>
        </TitleWrapper>
      </TD>
      <TD>
        <Typography variant='body1' color='400'>
          {clientName}
        </Typography>
      </TD>
      <TD>
        {!open && (
          <Typography variant='body1' color='400'>
            {versions}
          </Typography>
        )}
      </TD>
      <TD>
        <Typography variant='body1' color='400'>
          {`${lastUpdated.toLocaleDateString('en-US')}`}
        </Typography>
      </TD>
      <TD>
        <Button
          variant='primary'
          prefixIcon={<Icon iconName='rebuild' fill='#fff' />}
          isLoading={rebuildInProgress}
          style={{ width: 95 }}
          disabled={rebuildInProgress || !canBeRebuilt}
          onClick={onRebuild}
        >
          Update
        </Button>
      </TD>
      <TD>
        <Typography variant='body1' color='400'>
          {topFeedback}
          <br />
          {topSellerBarrier}
        </Typography>
      </TD>
      <TD style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
        <ButtonWrapper>
          <TableButton onClick={() => window.open(`/present/presentation/${hash}`, '_blank')}>
            Web
          </TableButton>
          <TableButton
            disabled={buildInProgress}
            onClick={() => onPdfClick(pdf)}
            style={{ width: 53, justifyContent: 'center' }}
          >
            {buildInProgress ? <Loader /> : 'PDF'}
          </TableButton>
        </ButtonWrapper>

        <ButtonWrapper
          style={{ border: '1px solid #DDDDDD', borderTop: 'none', borderBottom: 'none' }}
        >
          <TableButton
            disabled={buildInProgress || !sessionsCount}
            onClick={() => onAnalyticsOpen(hash)}
            style={{ width: 53, justifyContent: 'center' }}
            iconName='analytics'
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <TableButton iconName='mail' onClick={() => onMailClick(hash)} />
          <TableButton iconName='remove' onClick={() => onRemoveClick(id)} />
        </ButtonWrapper>
      </TD>
    </PresentationItemContainer>
  );
};
