import styled from 'styled-components';
import { PastPresentationsTable } from './components/PastPresentationsTable/PastPresentationsTable';
import Loader from '../../../../components/Loader/Loader';

import { AnalyticsModalProvider } from './providers/AnalyticsModalProvider';
import { useLoadPresentationsWithPdfPooling } from './hooks/useLoadPresentations';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePastPresentationsTable } from './hooks/usePastPresentationsTable';
import Filters from './components/Filters/Filters';

const Container = styled.div`
  padding: 20px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
`;

const SectionHeaderContainer = styled.div`
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 980px) {
    flex-direction: column;
    align-items: start;
  }
`;

const SectionTitle = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.gray[400]};
  font-weight: 700;
  white-space: nowrap;
`;

const TableContainer = styled.div<{ horizontalPadding: number }>`
  width: 100%;
  position: relative;

  @media all and (max-width: 1550px) {
    overflow-x: auto;
    position: absolute;
    left: 0;
    padding: ${({ horizontalPadding }) => `0 ${horizontalPadding}px`};
  }
`;

const TableWrapper = styled.div<{ containerHeight: number }>`
  height: 'auto';

  @media all and (max-width: 1550px) {
    height: ${({ containerHeight }) => containerHeight}px;
  }
`;

export const PastPresentationsSection = () => {
  const { isLoading } = useLoadPresentationsWithPdfPooling();
  const [containerHeight, setContainerHeight] = useState(0);
  const [horizontalPadding, setHorizontalPadding] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { table, numberOfShownRows } = usePastPresentationsTable();

  const onResize = useCallback(() => {
    if (!tableContainerRef?.current || !tableWrapperRef?.current || !containerRef?.current) return;

    if (window.innerWidth < 1550) {
      const height = tableContainerRef?.current?.getBoundingClientRect()?.height;

      const offset = tableWrapperRef?.current?.offsetLeft;

      setHorizontalPadding(offset || 0);

      setContainerHeight(height || 0);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    onResize();
  }, [onResize, numberOfShownRows]);

  if (isLoading) return <Loader size='3x' centered />;

  return (
    <AnalyticsModalProvider>
      <Container ref={containerRef}>
        <SectionHeaderContainer>
          <SectionTitle>Past Presentations</SectionTitle>
          <Filters table={table} />
        </SectionHeaderContainer>
        <TableWrapper containerHeight={containerHeight} ref={tableWrapperRef}>
          <TableContainer ref={tableContainerRef} horizontalPadding={horizontalPadding}>
            <PastPresentationsTable table={table} />
          </TableContainer>
        </TableWrapper>
      </Container>
    </AnalyticsModalProvider>
  );
};
