import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { StorageService } from '../../services/storageService';
import { FullScreenLoader } from '../../components/Loader/FullScreenLoader';

export const WhiteLabelLogin = () => {
  const history = useHistory();
  const token = useUrlSearchParam('token');

  useEffect(() => {
    StorageService.save('token', token);

    history.push('/home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <FullScreenLoader />;
};
