import styled from 'styled-components';
import { Theme } from '../../../../config/themes/types';

export const ButtonElement = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 230,
  height: 78,
  outline: 'none',
  border: 'none',
  cursor: 'pointer',
  backgroundColor: '#fff',
  borderRadius: 8,
  transition: 'all .2s',
  paddingLeft: 14.5,
  '&:not(:last-child)': { marginRight: 18 },
  '&:hover': {
    backgroundColor: theme.colors.gray[100],
    transform: 'translateY(-1px)',
  },
  '@media (max-width: 807px)': {
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
}));

type Colors = 'primary' | 'secondary' | 'yellow';

interface WithColor {
  color: Colors;
}

const getStylesFromColor = (theme: Theme, color: Colors): string => {
  switch (color) {
    case 'primary':
      return theme.colors.primary.main;
    case 'secondary':
      return theme.colors.secondary.main;
    case 'yellow':
      return theme.colors.warning;
  }
};

export const Circle = styled('div')({
  height: 43,
  width: 43,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: '#444',
});

export const TextWrapper = styled('div')({
  marginLeft: 8,
});
