import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  ActionsContainer,
  Container,
  Dropdown,
  DropdownItem,
  DropdownWrapper,
} from './Header.styles';
import { AuthApi } from '../../../api/AuthApi';
import { Button } from '../../../components/Common/V2/Button/Button';
import { useOutsideClick } from '../../../services/useOutsideClick';
import { useUser } from '../../../components/Slide/providers/UserProvider';

export const Header: React.FC = () => {
  const user = useUser();

  const history = useHistory();
  const [open, setOpen] = useState<React.RefObject<HTMLDivElement> | null>(null);

  const themeElRef = React.useRef<HTMLDivElement>(null);

  const logout = async () => {
    await AuthApi.logout();
    return history.push('/');
  };

  useOutsideClick(open, () => {
    setOpen(null);
  });

  return (
    <Container>
      <ActionsContainer ref={themeElRef}>
        <Button
          variant='secondary'
          onClick={() => {
            setOpen(themeElRef);
          }}
          suffixIcon={
            <FontAwesomeIcon
              fontSize={12}
              style={{
                transform: `rotate(${open ? '180deg' : '0deg'})`,
                transition: 'transform 0.3s',
              }}
              icon={'chevron-down'}
            />
          }
        >
          {user?.name}
        </Button>
        <Dropdown isOpen={!!open}>
          <DropdownWrapper>
            <DropdownItem onClick={logout}>Click to logout</DropdownItem>
          </DropdownWrapper>
        </Dropdown>
      </ActionsContainer>
    </Container>
  );
};
