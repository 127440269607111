import styled from 'styled-components';

type WithOpenDropdown = {
  isOpen?: boolean;
};

export const Container = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 60,
  backgroundColor: '#fff',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  flexShrink: 0,
  padding: theme.spacing(0, 4),
}));

interface WithOpen {
  open?: number;
}

export const HamburgerContainer = styled('div')<WithOpen>(({ open, theme }) => ({
  cursor: 'pointer',
  transition: 'all .2s',
  opacity: open ? 1 : 0.85,
  '&:hover': {
    opacity: 1,
  },
}));

export const Line = styled('div')<WithOpen>(({ open }) => ({
  display: 'block',
  height: 2,
  width: 25,
  borderRadius: 200,
  backgroundColor: '#333',
  transition: 'all .4s',
  '&:nth-of-type(2)': {
    transform: `translateX(${open ? '3px' : '0'})`,
  },
  '&:not(:last-child)': {
    marginBottom: 4,
  },
}));

export const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 'auto',
  position: 'relative',
}));

export const Dropdown = styled('div')<WithOpenDropdown>(({ isOpen }) => ({
  width: '100%',
  position: 'absolute',
  top: '100%',
  left: 0,
  zIndex: 2,
  maxHeight: isOpen ? 100 : 0,
  overflow: 'hidden',
  transition: 'max-height .2s ease-out',
}));

export const DropdownWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.colors.gray[200]}`,
  borderRadius: 5,
  backgroundColor: '#fff',
}));

export const DropdownItem = styled('p')(({ theme }) => ({
  fontSize: 13,
  fontWeight: 400,
  color: theme.colors.gray[400],
  cursor: 'pointer',
  padding: '5px 14px',
  '&:hover': {
    backgroundColor: theme.colors.gray[200],
  },
}));

export const Role = styled('span')(({ theme }) => ({
  fontSize: 11,
  fontWeight: 700,
  marginRight: theme.spacing(1),
  textTransform: 'uppercase',
}));
