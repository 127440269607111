import React, { useEffect, useMemo } from 'react';

import Loader from '../../components/Loader/Loader';
import { Timestamp } from '../../services/Timestamp';
import { Container, LibraryPageContainer } from './LibraryPage.styles';
import { LibraryHeader } from './LibraryHeader/LibraryHeader.component';
import { useUser } from '../../components/Slide/providers/UserProvider';
import { LibraryContent } from './LibraryContent/LibraryContent.component';
import { DefaultConfigProvider } from '../../services/DefaultConfigProvider';
import { withLoadedUser } from '../../hoc/withLoadedUser';
import { CreateSlidePropsProvider } from './providers/CreateSlidePropsProvider';
import { SelectedSlideProvider } from '../../providers/providers/SelectedSlideProvider';
import { CategoryProvider, useCategory } from '../../providers/providers/CategoryProvider';
import { UiConfigProvider, useSetUiConfigs } from '../../providers/providers/UiConfigProvider';
import { PresentationTypeProvider } from '../../providers/providers/PresentationTypeProvider';
import { ActiveSectionProvider } from '../../components/Slide/providers/ActiveSectionProvider';
import { PreviewModeProvider } from '../PresentationEdit/PresentationCreationContent/providers/PreviewModeProvider';
import { CoreSlidesConfigProvider } from '../../providers/providers/CoreSlidesConfigProvider';
import { useSlides } from '../../providers/providers/SlidesProvider';
import { useOrder } from '../../providers/providers/OrderProvider';
import { useSections } from '../../providers/providers/SectionsProvider';
import { useUserCategoryCustomizations } from '../../hooks/useUserCategoryCustomizations';
import { OrderValidator } from '../../services/validators/OrderValidator';
import { LibrarySlidesExtractor } from './services/LibrarySlidesExtractor';
import { CreatedAtProvider, useCreatedAt } from '../../providers/providers/CreatedAtProvider';
import { useLoadPersonalized } from '../../data/personalized/useLoadPersonalized';
import { isPopulatedPersonalizedSlide } from '../../services/isPopulatedPersonalizedSlide';
import { useLibraryDynamicSlides } from '../../hooks/useLibraryDynamicSlides';
import { useHasWTR } from '../../features/WinTheRepresentation/hooks/useHasWTR';
import { LibraryConfigs } from '../../features/Library/services/LibraryConfigs';

const Content: React.FC = () => {
  const { category } = useCategory();
  const setUiConfigs = useSetUiConfigs();
  const { setSlides } = useSlides();
  const { setSectionsSlideOrder, setSectionsOrder } = useOrder();
  const { setSections } = useSections();
  const { setCreatedAt } = useCreatedAt();

  const hasWTR = useHasWTR();

  const { hash, useSpecialBranding } = useUser();
  const dynamic = useLibraryDynamicSlides(hash);
  const customizations = useUserCategoryCustomizations(hash, null, useSpecialBranding);
  const { data } = useLoadPersonalized(hash);

  const isLoading = useMemo(
    () => dynamic.isLoading || customizations.isLoading,
    [dynamic.isLoading, customizations.isLoading],
  );
  const isFetching = useMemo(
    () => dynamic.isFetching || customizations.isFetching,
    [dynamic.isFetching, customizations.isFetching],
  );

  useEffect(
    function onUserCustomizationLoaded() {
      if (!category || isLoading || isFetching) return;

      const ui = customizations?.data?.ui;

      const uiConfigs = ui ?? DefaultConfigProvider.getConfigs()[category].ui;

      const orderValidator = new OrderValidator();

      const sectionsSlideOrder = orderValidator.getFullSectionsSlidesOrder(
        LibraryConfigs.getPredefinedSectionSlides(),
        dynamic.data,
      );

      const librarySectionSlides = LibrarySlidesExtractor.getLibrarySlidesMap(
        {
          ...LibraryConfigs.getSlides(),
          ...dynamic.data,
        },
        !hasWTR,
      );

      const notPopulatedSlides = ['aboutMe', 'testimonials'].filter(
        slideId => !isPopulatedPersonalizedSlide(slideId, data),
      );

      if (notPopulatedSlides.length) {
        sectionsSlideOrder.whyIAmTheRightFit = sectionsSlideOrder.whyIAmTheRightFit.filter(
          sId => !notPopulatedSlides.includes(sId),
        );

        notPopulatedSlides.forEach(sId => {
          // @ts-ignore
          delete librarySectionSlides[sId];
        });
      }

      setUiConfigs(uiConfigs);
      setSectionsOrder(LibraryConfigs.getFullOrder().sections);
      setSectionsSlideOrder(sectionsSlideOrder);
      setSlides(librarySectionSlides);
      setSections(LibraryConfigs.getSections());
      setCreatedAt(Timestamp.now());
    },
    [
      isLoading,
      isFetching,
      dynamic,
      customizations?.data?.ui,
      category,
      setUiConfigs,
      setSectionsOrder,
      setSectionsSlideOrder,
      setSlides,
      setSections,
      setCreatedAt,
      data,
      hasWTR,
    ],
  );

  if (isLoading) {
    return <Loader style={{ marginTop: 100 }} centered size={'4x'} />;
  }

  return (
    <CreateSlidePropsProvider>
      <LibraryPageContainer>
        <Container>
          <LibraryHeader />
          <LibraryContent />
        </Container>
      </LibraryPageContainer>
    </CreateSlidePropsProvider>
  );
};

export const Library: React.FC = withLoadedUser(() => (
  <CoreSlidesConfigProvider>
    <UiConfigProvider>
      <CategoryProvider>
        <PresentationTypeProvider>
          <PreviewModeProvider>
            <CreatedAtProvider>
              <ActiveSectionProvider activeSection={'whyIAmTheRightFit'}>
                <SelectedSlideProvider>
                  <Content />
                </SelectedSlideProvider>
              </ActiveSectionProvider>
            </CreatedAtProvider>
          </PreviewModeProvider>
        </PresentationTypeProvider>
      </CategoryProvider>
    </UiConfigProvider>
  </CoreSlidesConfigProvider>
));
