import styled, { CSSProperties, useTheme } from 'styled-components';
import { ReactElement } from 'react';
import { Icon } from '../../../PastPresentation.styles';
import { IconName } from '../../../../../../../components/Common/Icon/Icon.types';

interface Props {
  onClick: () => void;
  children?: ReactElement | string;
  iconName?: IconName;
  disabled?: boolean;
  style?: CSSProperties;
}

export const Button = ({ children, iconName, onClick, disabled, style }: Props) => {
  const { colors } = useTheme();
  return (
    <Container onClick={onClick} disabled={disabled} style={style}>
      {children}
      {iconName && (
        <Icon iconName={iconName} style={{ position: 'relative' }} fill={colors.v2.gray[400]} />
      )}
    </Container>
  );
};

const Container = styled.div<{ disabled?: boolean }>`
  height: 35px;
  padding: 8px 11px;
  border-radius: 5px;
  transition: all 0.2s;
  background-color: ${({ theme }) => theme.colors.v2.gray[100]};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray[200]};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.v2.gray[400]};
`;
