import styled from 'styled-components';

import { Typography, Icon } from '../../../../components/Common';

export { Typography, Icon };

export const MainSectionContainer = styled('div')({
  flex: 1,
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: '3px 5px 12px rgba(0, 0, 0, 0.1)',
  paddingLeft: '2.5%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  overflow: 'hidden',
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '46%',
  '@media all and (max-width: 1394px)': {
    width: '56%',
  },
});

export const Description = styled('div')({
  width: '100%',
  marginTop: 15,
  marginBottom: 20,
});

export const ImageWrapper = styled('div')({
  width: '47%',
  height: '100%',
  '@media all and (max-width: 1394px)': {
    width: '44%',
  },
});

export const Title = styled(Typography)({
  fontSize: 18,
  '@media all and (max-width: 1235px)': {
    fontSize: 16,
    lineHeight: 1.4,
  },
});

export const Text = styled(Typography)({
  fontSize: 14,
  lineHeight: 1.4,
  '@media all and (max-width: 1432px)': {
    fontSize: 13,
  },
});

export const PresentationImage = styled('img')({
  width: '100%',
  display: 'block',
  objectFit: 'contain',
  objectPosition: 'right bottom',
  height: '100%',
});
