import React from 'react';
import { Header } from './Header/Header.component';
import { Header as NewHeader } from './Header/Header';
import { Container, Wrapper, ContentContainer } from './MainLayout.styles';
import { useHasWTR } from '../../features/WinTheRepresentation/hooks/useHasWTR';

type Props = { isWhiteBg?: boolean; className?: string };

export const MainLayout: React.FC<Props> = ({ children, isWhiteBg, className = '' }) => {
  const isWTR = useHasWTR();

  const HeaderToRender = isWTR ? NewHeader : Header;

  return (
    <Container>
      <Wrapper style={isWTR ? { paddingTop: 58 } : {}}>
        <HeaderToRender />
        <ContentContainer className={className} isWhiteBg={isWhiteBg}>
          {children}
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};
