import React from 'react';

import { Section } from './sections.styles';
import { Typography } from '../../../components/Common';
import { AnalyticsSummary } from '../components/AnalyticsSummary/AnalyticsSummary.component';
import { PastPresentationComponent } from '../components/PastPresentation/PastPresentation.component';

const PastPresentationSection: React.FC = () => {
  return (
    <Section column>
      <Typography variant='h3'>Past Presentations</Typography>
      <AnalyticsSummary />
      <PastPresentationComponent />
    </Section>
  );
};

export default PastPresentationSection;
