import { IconName } from '../../../../components/Common/Icon/Icon.types';
import React from 'react';

import { Icon, Typography } from '../../../../components/Common';
import { ItemContainer, ItemContentWrapper } from './Configuration.styles';

interface ConfigurationItemComponentProps {
  icon: IconName;
  title: string;
  subtitle: string;
  url: string;
  progress?: number;
  externalLink?: boolean;
  onClick: (url: string) => void;
}

export const ConfigurationItemComponent: React.FC<ConfigurationItemComponentProps> = ({
  icon,
  title,
  subtitle,
  progress,
  url,
  onClick,
  externalLink = undefined,
}) => {
  return (
    <ItemContainer onClick={() => (externalLink ? (window.location.href = url) : onClick(url))}>
      <Icon iconName={icon} />
      <ItemContentWrapper>
        <Typography variant='h4' style={{ lineHeight: 1.2 }}>
          {title}
        </Typography>
        <Typography variant='body2' color='primary' style={{ lineHeight: 1.2 }}>
          {subtitle}
        </Typography>
      </ItemContentWrapper>
    </ItemContainer>
  );
};
