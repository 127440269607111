import { useHistory } from 'react-router';
import React, { useState, useEffect } from 'react';

import { Routes } from '../../Router/Routes';
import { TPresentationType } from '../../../types';
import { StorageService } from '../../../services/storageService';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { useUser } from '../../../components/Slide/providers/UserProvider';
import { ActionButtonComponent } from '../components/ActionButton/ActionButton.component';
import { Section, PresentationActionsWrapper, ActionButtonsWrapper } from './sections.styles';
import { NotifyMlsEmailPopup } from '../../../components/Common/NotifyMlsPopup/NotifyMlsEmailPopup.component';

export const CreatePresentationActionsSection: React.FC = () => {
  const history = useHistory();
  const { feedIds } = useUser()!;
  const userHasFeeds = feedIds?.length > 0;
  const onCreatePresentationClick = (presentationType: TPresentationType) => {
    history.push(`/create/search/address?new=true&type=${presentationType}`);
    window.scroll(0, 25);
  };

  return (
    <Section id='homepage-cp-actions' column background='primary'>
      <PresentationActionsWrapper>
        <ActionButtonsWrapper>
          {!userHasFeeds && <NotifyMlsEmailPopup />}
          {userHasFeeds && (
            <ActionButtonComponent
              title='Win the Listing'
              subtitle='Create for Seller'
              icon='trophy'
              onClick={() => onCreatePresentationClick('winTheListing')}
            />
          )}
          {userHasFeeds && (
            <ActionButtonComponent
              title='Win the Offer'
              subtitle='Create for Buyer'
              icon='offer'
              onClick={() => {
                onCreatePresentationClick('winTheOffer');
              }}
            />
          )}
          <ActionButtonComponent
            title='Present Info'
            subtitle='Create Presentation'
            icon='present'
            onClick={() =>
              history.push(
                `${Routes.presentationNew}?type=${PRESENTATION_TYPES.PRESENT_INFO}&action=create`,
              )
            }
          />
          {userHasFeeds && (
            <ActionButtonComponent
              title='Buyer Tour'
              subtitle='Create Buyer Tour'
              icon='mapWithPin'
              onClick={() =>
                history.push(
                  `${Routes.presentationNew}?type=${PRESENTATION_TYPES.BUYER_TOUR}&action=create`,
                )
              }
            />
          )}
        </ActionButtonsWrapper>
      </PresentationActionsWrapper>
    </Section>
  );
};
