import styled, { CSSObject } from 'styled-components';
import { Section } from '../../../../../pages/Home/sections/sections.styles';
import { useUiConfigColorsPrimary } from '../../../../../providers/providers/UiConfigColorProvider';
import {
  useUiConfigBodyLetterSpacing,
  useUiConfigFontsBody,
  useUiConfigFontsHeader,
  useUiConfigHeaderLetterSpacing,
} from '../../../../../providers/providers/UiConfigFontProvider';

export const Container = styled('div')({
  height: '100%',
  position: 'relative',
});

interface P {
  src: string;
  position: 'left' | 'right';
  imageStyles?: CSSObject;
  isEqualWidth?: boolean;
  isImageDominant?: boolean;
}

interface TextContainerProps {
  position: 'left' | 'right';
  isEqualWidth?: boolean;
  isImageDominant?: boolean;
}

const getTextContainerWidth = (isEqualWidth?: boolean, isImageDominant?: boolean) => {
  switch (true) {
    case isEqualWidth:
      return '50%';
    case isImageDominant:
      return '31%';
    default:
      return '70%';
  }
};

const getImageContainerWidth = (isEqualWidth?: boolean, isImageDominant?: boolean) => {
  switch (true) {
    case isEqualWidth:
      return '64%';
    case isImageDominant:
      return '69%';
    default:
      return '30%';
  }
};

export const ImageContainer = styled('div')<P>(
  ({ src, position, imageStyles, isEqualWidth, isImageDominant }) => ({
    width: getImageContainerWidth(isEqualWidth, isImageDominant),
    background: `url(${src})`,
    position: 'absolute',
    [position]: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    ...imageStyles,
  }),
);

export const TextCointainer = styled('div')<TextContainerProps>(
  ({ position, isEqualWidth, isImageDominant }) => ({
    backgroundColor: useUiConfigColorsPrimary(),
    height: '100%',
    padding: '3% 4%',
    width: getTextContainerWidth(isEqualWidth, isImageDominant),
    marginLeft: position === 'right' ? 'auto' : 0,
    zIndex: 1,
  }),
);

export const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background:
    'linear-gradient(163.23deg, rgba(0, 0, 0, 0.304) -3.3%, rgba(236, 236, 236, 0.204) 49.23%, rgba(0, 0, 0, 0.276) 103.99%)',
  backdropFilter: 'blur(10px)',
});

export const TextContentWrapper = styled('div')(({ theme }) => ({
  padding: '8% 8% 0 8%',
  color: '#fff',
  position: 'relative',
  zIndex: 10,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const Heading = styled('h3')(({ theme }) => ({
  paddingBottom: 6,
  fontSize: 22,
  fontWeight: 700,
  borderBottom: '1px solid #fff',
  textTransform: 'uppercase',
  textAlign: 'left',
  marginBottom: 30,
  fontFamily: useUiConfigFontsHeader(),
  letterSpacing: useUiConfigHeaderLetterSpacing(),
}));

export const common: React.CSSProperties = {
  fontSize: 20,
  lineHeight: 1.6,
  textAlign: 'justify',
  color: '#fff',
};

export const LI = styled('li')(({ theme }) => ({
  ...common,
  marginTop: 10,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const Paragraph = styled('p')(({ theme }) => ({
  ...common,
  marginBottom: 15,
  fontFamily: useUiConfigFontsBody(),
  letterSpacing: useUiConfigBodyLetterSpacing(),
}));

export const UL = styled('ul')({});
export const OL = styled('ol')({});

export const ListItemBold = styled.span`
  font-weight: 700;
`;
