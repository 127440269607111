import { ROUTING_PREFIX } from '../../constants/routingPrefix';
import { Environment } from '../../services/DevelopmentUtilities/Environment';
import { StorageService } from '../../services/storageService';

export function constructWhitelabelLoginUrl(website: string) {
  const token = StorageService.read('token');

  return Environment.isDevelopment()
    ? `http://${website}:3000${ROUTING_PREFIX}/whitelabel-login?token=${token}`
    : `https://${website}${ROUTING_PREFIX}/whitelabel-login?token=${token}`;
}
