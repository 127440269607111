import { corePresentApi } from '../../../../../api/CorePresentApi';
import { TAnalyticsSummary } from '../AnalyticsSummary.component';

export class AnalyticsSummaryApi {
  static async fetchSummary(): Promise<TAnalyticsSummary> {
    const res = await corePresentApi.get(`/sessions/stats`);

    return {
      presentationCount: res.presentation_count,
      avgDuration: res.average_duration,
      avgOpenRate: (res.open_rate ? Number(res.open_rate) || 0 : 0) * 100,
      // addressWinRate: 0,
      avgSellerResponse: res.average_seller_response,
      avgBuyerResponse: res.average_buyer_response,
      avgBuyerFeedbackOption: res.average_buyer_feedback_option,
      avgSellerFeedbackOption: res.average_seller_feedback_option,
    };
  }
}
