import React from 'react';
import { Icon, Typography } from '../../../../components/Common';
import { Circle, ButtonElement, TextWrapper } from './ActionButton.styles';

interface ActionButtonComponentProps {
  icon: 'property' | 'trophy' | 'present' | 'offer' | 'mapWithPin';
  title: string;
  subtitle: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ActionButtonComponent: React.FC<ActionButtonComponentProps> = ({
  icon,
  title,
  subtitle,
  onClick,
}) => {
  return (
    <ButtonElement type='button' onClick={onClick}>
      <Circle>
        <Icon iconName={icon} fill={icon !== 'mapWithPin' ? '#fff' : 'none'} />
      </Circle>
      <TextWrapper>
        <Typography variant='h4' color='400' style={{ lineHeight: 1.05 }}>
          {title}
        </Typography>
        <Typography variant='body2' color='primary'>
          {subtitle}
        </Typography>
      </TextWrapper>
    </ButtonElement>
  );
};
