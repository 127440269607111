import { useEffect } from 'react';
import { useSetAtom } from 'jotai';

import { subjectAtom } from '../state/subjectAtom';
import { extractSubject } from '../services/extractSubject';
import { reportPropertiesAtom } from '../state/reportPropertiesAtom';
import { extractReportProperties } from '../services/extractReportProperties';
import { PropertyGroups, RawReport, TPresentation, TSuperPresentation } from '../../../types';
import { isPresentationViewAtom } from '../state/isPresentationViewAtom';
import { searchCriteriaAtom } from '../state/searchCriteriaAtom';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { thumbsUpAtom } from '../state/thumbsUpAtom';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';
import { propertiesNotesAtom } from '../state/propertiesNotesAtom';
import { pricingFavorabilityAtom } from '../state/pricingFavorabilityAtom';
import { extractPricingFavorability } from '../services/extractPricingFavorability';
import { pricingRateOfChangeAtom } from '../state/pricingRateOfChangeAtom';
import { extractPricingRateOfChange } from '../services/extractPricingRateOfChange';
import { sellingPaceAtom } from '../state/sellingPaceAtom';
import { extractSellingPace } from '../services/extractSellingPace';
import { activeShelfLifeAtom } from '../state/activeShelfLifeAtom';
import { extractActiveShelfLfe } from '../services/extractActiveShelfLife';
import { compAveragesAtom } from '../state/compAveragesAtom';
import { estimatesAtom } from '../state/estimatesAtom';
import { flagsAtom } from '../state/flagsAtom';
import { selectedCategoriesAtom } from '../state/selectedCategoriesAtom';
import { customMarkersAtom } from '../state/customMarkersAtom';
import { customEstimatesAtom } from '../state/customEstimatesAtom';
import { getPropertiesNotes } from '../services/getPropertiesNotes';

export function useSetPresentationToState(presentation?: TSuperPresentation) {
  const setSubject = useSetAtom(subjectAtom);
  const setProperties = useSetAtom(reportPropertiesAtom);
  const setIsPresentationView = useSetAtom(isPresentationViewAtom);
  const setSearchCriteria = useSetAtom(searchCriteriaAtom);
  const setHighlightedProperties = useSetAtom(highlightedPropertiesAtom);
  const setThumbsUp = useSetAtom(thumbsUpAtom);
  const setExcluded = useSetAtom(excludedPropertyIdsAtom);
  const setNotes = useSetAtom(propertiesNotesAtom);
  const setPricingFavorability = useSetAtom(pricingFavorabilityAtom);
  const setRateOfChange = useSetAtom(pricingRateOfChangeAtom);
  const setSellingPace = useSetAtom(sellingPaceAtom);
  const setActiveShelfLife = useSetAtom(activeShelfLifeAtom);
  const setCompAverages = useSetAtom(compAveragesAtom);
  const setEstimates = useSetAtom(estimatesAtom);
  const setSelectedCategories = useSetAtom(selectedCategoriesAtom);
  const setFlags = useSetAtom(flagsAtom);
  const setCustomMarkers = useSetAtom(customMarkersAtom);
  const setCustomEstimates = useSetAtom(customEstimatesAtom);

  const report = (presentation as TPresentation)?.reportData?.report?.reportDetailed as RawReport;
  const searchCriteria = (presentation as TPresentation)?.reportData?.search?.searchCriteria;
  const highlightedProperties = (presentation as TPresentation)?.reportData?.result?.highlighted;
  const thumbsUp = (presentation as TPresentation)?.reportData?.result?.thumbsUp;
  const excluded = (presentation as TPresentation)?.reportData?.result?.excluded;
  const compAverages = (presentation as TPresentation)?.reportData?.report?.reportDetailed
    .compAverages;
  const estimates = (presentation as TPresentation)?.reportData?.report?.reportDetailed.estimates;

  const customMarkers = (presentation as TPresentation)?.reportData?.result?.customMarkers;
  const customEstimates = (presentation as TPresentation)?.reportData?.result?.customEstimates;
  const flags = (presentation as TPresentation)?.reportData?.report?.reportDetailed.flags;
  const selectedCategories = (presentation as TPresentation)?.reportData?.result
    ?.selectedCategories;

  const properties =
    (presentation as TPresentation)?.version === 2
      ? (presentation as TPresentation)?.comparableProperties
      : report?.properties;

  const notes =
    (presentation as TPresentation)?.version === 2
      ? getPropertiesNotes(properties as PropertyGroups)
      : (presentation as TPresentation)?.reportData?.result?.notes;

  useEffect(() => {
    setIsPresentationView(true);

    if (!report) return;

    setProperties(extractReportProperties(properties, { filterExcluded: true }));
    setSubject(extractSubject(report));
    setSearchCriteria(searchCriteria);
    setHighlightedProperties(highlightedProperties);
    setThumbsUp(thumbsUp);
    setExcluded(excluded);
    setNotes(notes);
    setCustomMarkers(customMarkers);
    setPricingFavorability(extractPricingFavorability(report));
    setRateOfChange(extractPricingRateOfChange(report));
    setSellingPace(extractSellingPace(report));
    setActiveShelfLife(extractActiveShelfLfe(report));
    setCompAverages(compAverages);
    setEstimates(estimates);
    setCustomEstimates(customEstimates);

    setFlags(flags);
    setSelectedCategories(selectedCategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, searchCriteria]);
}
