import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import React, { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';

import { PastPresentationItem } from '../../types';
import { Routes } from '../../../../../Router/Routes';
import { TPresentationType } from '../../../../../../types';
import { AnalyticsModal } from '../AnalyticsModal/AnalyticsModal';
import { PRESENTATION_TYPES } from '../../../../../../constants/PresentationTypes';
import { AnalyticsModalEvents } from '../AnalyticsModal/services/AnalyticsModalEvent';
import { PastPresentationGroup } from './PastPresentationGroup/PastPresentationGroup.component';
import { PastPresentationHeadComponent } from './PastPresentationHead/PastPresentationHead.component';
import { PastPresentationItemEvents } from './PastPresentationGroup/PastPresentationItem.component';

const Table = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowX: 'auto',
}));

const TableBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 3),
  minWidth: 1193,
}));

type PastPresentationTableComponentProps = PastPresentationItemEvents & {
  presentations: PastPresentationItem[][];
  setPresentations: Dispatch<SetStateAction<PastPresentationItem[][]>>;
};
export const PastPresentationTableComponent: React.FC<PastPresentationTableComponentProps> = ({
  presentations,
  setPresentations,
  ...presentationItemEvents
}) => {
  const { push, replace } = useHistory();
  const { search } = useLocation();

  const onRebuild = React.useCallback(
    (hash: string, type: TPresentationType) => {
      const queryParams = `?type=${type}&action=rebuild&presentation=${hash}`;

      const pages = {
        [PRESENTATION_TYPES.BUYER_TOUR]: Routes.presentationNew,
        [PRESENTATION_TYPES.PRESENT_INFO]: Routes.presentationNew,
        [PRESENTATION_TYPES.WIN_THE_REPRESENTATION]: Routes.presentationNew,
        [PRESENTATION_TYPES.WIN_THE_LISTING]: `/create/search/details`,
        [PRESENTATION_TYPES.WIN_THE_OFFER]: `/create/search/details`,
      };

      if (!pages[type]) return;

      push(pages[type] + queryParams);
    },
    [push],
  );
  // use presentation id
  const [analyticsOpen, setAnalyticsOpen] = useState<string | undefined>();

  const openAnalytics = useCallback(
    (event: any) => {
      const presentationHash = event.detail?.payload;

      if (!presentationHash || analyticsOpen) return;

      setAnalyticsOpen(presentationHash);
    },
    [analyticsOpen],
  );

  useEffect(() => {
    AnalyticsModalEvents.subscribe(openAnalytics);
    return () => AnalyticsModalEvents.unsubscribe(openAnalytics);
  });

  useEffect(
    function openAnalyticsAuto() {
      const params = new URLSearchParams(search);
      const action = params.get('action');
      const presentationHash = params.get('presentation_hash');

      if (!presentationHash || action !== 'view_session') return;

      setAnalyticsOpen(presentationHash);
      replace({ search: '' });
    },
    [replace, search],
  );

  return (
    <Table>
      <PastPresentationHeadComponent setPresentations={setPresentations} />
      <TableBody>
        {presentations.map((items, i) => {
          if (!items.length) return;
          return (
            <PastPresentationGroup
              key={i}
              items={items}
              {...presentationItemEvents}
              onRebuildClick={onRebuild}
              analyticsOpen={analyticsOpen}
              onAnalyticsOpen={hash => setAnalyticsOpen(hash)}
            />
          );
        })}
      </TableBody>
      {analyticsOpen && (
        <AnalyticsModal hash={analyticsOpen} onClose={() => setAnalyticsOpen(undefined)} />
      )}
    </Table>
  );
};
