import { useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { TPresentation, TSuperPresentation } from '../types';
import { PRESENTATION_TYPES } from '../constants/PresentationTypes';
import { PresentationApi } from '../pages/Presentation/api/PresentationApi';
import { DummyPresentationApi } from '../api/DummyPresentation/DummyPresentationApi';
import { UserConfigStructureMigrator } from '../data/customizations/UserConfigStructureMigrator';

const fetchPresentationAndMergeWithDefault = async (
  presentationHash?: string,
): Promise<TSuperPresentation> => {
  const presentation = presentationHash
    ? await PresentationApi.fetch(presentationHash)
    : await DummyPresentationApi.fetch();

  if (presentation.type === PRESENTATION_TYPES.BUYER_TOUR) return presentation;

  // const defaultConfig = DefaultConfigProvider.getConfigs()[presentation.category].slides;
  // presentation.slides = SlidesConfigAdapter.toValid(defaultConfig, presentation.slides);

  UserConfigStructureMigrator.migrateUiConfig(presentation.ui);

  return presentation;
};

export const useIsReportInStore = () => useSelector<any>(store => !!store?.search?.api?.id);

export const useLoadDummyPresentation = () => usePresentation();

type TUsePresentationConfig = {
  omitGlobalStoreSet?: boolean;
};

export function usePresentation(hash?: string, config?: TUsePresentationConfig) {
  const dispatch = useDispatch();

  const query = useQuery<TSuperPresentation, Error>(
    ['presentation', hash],
    () => fetchPresentationAndMergeWithDefault(hash),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      onSuccess: data => {
        if (!data || data?.type === PRESENTATION_TYPES.BUYER_TOUR || config?.omitGlobalStoreSet)
          return;
        dispatch({ type: 'GLOBAL_STORE_SET', payload: (data as TPresentation)?.reportData });
      },
    },
  );

  return query;
}

export const useSetPresentation = (hash?: string) => {
  const queryClient = useQueryClient();

  return (presentation: TSuperPresentation) => {
    queryClient.setQueryData(['presentation', hash], presentation);
  };
};

export const useInvalidatePresentation = (hash?: string) => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(['presentation', hash]);
};
