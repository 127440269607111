import React from 'react';

import { pricingFavorabilityData } from '../data';
import { CATEGORIES } from '../../../../../../../../constants/categories';
import { PdfFrame } from '../../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { useCategory } from '../../../../../../../../providers/providers/CategoryProvider';
import { SlideWithGraphWrapperPdf } from '../../../../../components/common/SlideWithGraphWrapperPdf';
import { useSelector } from 'react-redux';
import { MoneyFormatter } from '../../../../../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { PricingFavorabilityChart } from '../../../../../../../../features/report/charts/pricing-favorability/PricingFavorabilityChart';

const PricingFavorabilityPdf: React.FC = () => {
  const { category } = useCategory();
  const data = useSelector(
    (state: any) => state?.report?.reportDetailed?.graphs?.pricing_favorability?.graph ?? [],
  );

  const value = data.length > 0 ? MoneyFormatter.format(data[data.length - 1]?.price ?? 0) : '';

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL}>
      <SlideWithGraphWrapperPdf
        heading={pricingFavorabilityData.heading}
        title={value}
        subtitle={pricingFavorabilityData.subtitle}
        description={pricingFavorabilityData.description}
        isReversed
      >
        <PricingFavorabilityChart />
      </SlideWithGraphWrapperPdf>
    </PdfFrame>
  );
};

export default PricingFavorabilityPdf;
