import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { AuthApi } from '../../api/AuthApi';
import { useAuthMe } from '../../hooks/useAuthMe';
import { corePresentApi } from '../../api/CorePresentApi';
import { useUrlSearchParam } from '../../hooks/useQueryParams';
import { StorageService } from '../../services/storageService';
import { ROUTING_PREFIX } from '../../constants/routingPrefix';
import { GuestLayout } from '../../layouts/GuestLayout/GuestLayout';
import { CurrentUserService } from '../../services/CurrentUserService';
import { ANIMATION_DURATION, AnimationLoader } from './AnimationLoader';
import { FEATURES, useHasFeatureFlag } from '../../hooks/useFeatureFlags';
import { constructWhitelabelLoginUrl } from '../../features/whitelabel/constructWhitelabelLoginUrl';

const Container = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '15vh',
});

export const SsoPage: React.FC = () => {
  const KVCORE_PROVIDER = 'kvcore';
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const primaryColor = useUrlSearchParam('primaryColor');

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { data: me } = useAuthMe(Boolean(isAuthenticated));
  const hasWL = useHasFeatureFlag(FEATURES.BoldTrailWhiteLabel, false);

  const processCallback = async (query: string) => {
    try {
      if (AuthApi.isLoggedIn()) {
        await corePresentApi.post('/auth/logout');
        StorageService.remove('token');
        CurrentUserService.remove();
      }

      const ssoTimer = new Date();
      const response = await AuthApi.processSsoCallback(KVCORE_PROVIDER, '?' + query);
      const ssoDuration = new Date().getTime() - ssoTimer.getTime();

      StorageService.save('token', response.token);

      setTimeout(() => {
        if (primaryColor) {
          setIsAuthenticated(true);
          return;
        }

        window.location.href = window.location.origin + ROUTING_PREFIX;
      }, ANIMATION_DURATION - ssoDuration);
    } catch (e: any) {
      setLoading(false);
      setError(true);
      setMessage('An error occurred. Please get in touch with us.');
    }
  };

  useEffect(() => {
    processCallback(query.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!me?.website || !hasWL || !isAuthenticated || !primaryColor) return;

    window.location.href = constructWhitelabelLoginUrl(me.website);
  }, [hasWL, me?.website, isAuthenticated, primaryColor]);

  return (
    <GuestLayout hideHeader>
      <Container>
        {loading && <AnimationLoader />}
        {error && <p style={{ color: 'red' }}>{message}</p>}
      </Container>
    </GuestLayout>
  );
};
