import styled from 'styled-components';
import { Input, Button, Icon, Typography } from '../../../../components/Common';

export { Input, Button, Icon };

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  borderRadius: 8,
  backgroundColor: '#fff',
  marginTop: theme.spacing(2),
  boxShadow: theme.shadows[2],
  minHeight: 300,
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(3),
}));

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  display: 'flex',
  'button:first-child': {
    marginRight: theme.spacing(1),
  },
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
