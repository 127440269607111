import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  AnalyticsItem,
  AnalyticsWrapper,
  Container,
  Label,
  ValueText,
} from './AnalyticsSummary.styles';

import { useAnalyticsSummaryData } from './useAnalyticsSummaryData';
import { MORE_TIME_DESCRIPTIONS } from '../../../../types';

type label =
  | 'presentationCount'
  | 'avgDuration'
  | 'avgOpenRate'
  // | 'addressWinRate'
  | 'avgSellerResponse'
  | 'avgBuyerResponse';

export type TAnalyticsSummary = {
  [K in label]: string | number | null;
} & {
  avgBuyerFeedbackOption: keyof typeof MORE_TIME_DESCRIPTIONS;
  avgSellerFeedbackOption: keyof typeof MORE_TIME_DESCRIPTIONS;
};

export type TAnalyticsSummaryData = {
  label: string;
  value?: string | number | null;
  isSmallFont?: boolean;
};

export const AnalyticsSummary: React.FC = () => {
  const { analyticsSummaryData, isLoading } = useAnalyticsSummaryData();

  if (!analyticsSummaryData || isLoading) return null;

  return (
    <Container>
      <AnalyticsWrapper>
        {analyticsSummaryData.map(data => {
          const { label, value, isSmallFont } = data;
          return (
            <AnalyticsItem key={label}>
              <ValueText isSmallFont={isSmallFont}>{value ? value : 'N/A'}</ValueText>
              <Label>{label}</Label>
            </AnalyticsItem>
          );
        })}
      </AnalyticsWrapper>
    </Container>
  );
};
