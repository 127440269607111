import React from 'react';

import { Section, Wrapper } from './sections.styles';
import { ConfigurationComponent } from '../components/Configuration/Configuration.component';
import { MainSection } from '../components/MainSection/MainSection.component';

export const MainConfigSection: React.FC = () => {
  return (
    <Section column>
      <Wrapper>
        <MainSection />
        <ConfigurationComponent />
      </Wrapper>
    </Section>
  );
};
