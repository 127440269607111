import styled from 'styled-components';

type WithSmallFont = {
  isSmallFont?: boolean;
};

export const Container = styled('div')({
  backgroundColor: '#fff',
  borderRadius: 8,
  boxShadow: '2px 5px 8px rgba(0, 0, 0, 0.03)',
  padding: '17px 2px',
  marginTop: 15,
  marginBottom: 10,
});

export const AnalyticsWrapper = styled('div')({
  display: 'flex',
  minWidth: 917,
  maxWidth: 1091,
  '@media all and (max-width: 1000px)': {
    flexWrap: 'wrap',
    minWidth: 'auto',
    justifyContent: 'center',
  },
});

export const AnalyticsItem = styled('div')(({ theme }) => ({
  minWidth: 152,
  minHeight: 76,
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 10px',
  textAlign: 'center',
  '&:not(:last-of-type)': {
    borderRight: `1px solid ${theme.colors.gray[200]}`,
  },
  '@media all and (max-width: 1000px)': {
    flex: '0 1 auto',
    width: 152,
    marginTop: 10,
  },
  '@media all and (max-width: 996px)': {
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.colors.gray[200]}`,
    },
    '&:nth-of-type(5)': {
      borderRight: 'none',
    },
    '@media all and (max-width: 830px)': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.colors.gray[200]}`,
      },
      '&:nth-of-type(4)': {
        borderRight: 'none',
      },
    },
    '@media all and (max-width: 665px)': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.colors.gray[200]}`,
      },
      '&:nth-of-type(3)': {
        borderRight: 'none',
      },
    },
    '@media all and (max-width: 499px)': {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.colors.gray[200]}`,
      },
      '&:nth-of-type(2n + 2)': {
        borderRight: 'none',
      },
    },
    '@media all and (max-width: 334px)': {
      '&:not(:last-of-type)': {
        borderRight: `none`,
      },
    },
  },
}));

export const ValueText = styled('h5')<WithSmallFont>(({ theme, isSmallFont }) => ({
  fontSize: isSmallFont ? 16 : 24,
  fontWeight: 500,
  color: theme.colors.primary.main,
  marginBottom: 5,
}));

export const Label = styled('h6')({
  fontSize: 13,
  fontWeight: 400,
});
