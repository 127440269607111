import React, { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import SellingPacePdf from '../components/sellingPace/pdf/SellingPacePdf';
import ActiveShelfLifePdf from '../components/activeShelfLife/pdf/ActiveShelfLifePdf';
import ActiveCompetitionPdf from '../components/activeCompetition/pdf/ActiveCompetitionPdf';
import PricePerSquareFootPdf from '../components/pricePerSquareFoot/pdf/PricePerSquareFootPdf';
import PricingRateOfChangePdf from '../components/pricingRateOfChange/pdf/PricingRateOfChangePdf';
import PricingFavorabilityPdf from '../components/pricingFavorability/pdf/PricingFavorabilityPdf';
import { useUser } from '../../../../providers/UserProvider';
import { useSelector } from 'react-redux';
import { getShelflifeAverage } from '../../../../../../pages/PresentationCreate/dash/services/getShelfLifeAverage';
import { usePricingFavorabilityData } from '../../../../../../features/report/charts/pricing-favorability/usePricingFavorabilityData';
import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';

export const MarketOverviewPdf: () => React.ReactElement[] = () => {
  const user = useUser();
  const isCanadaUser = user?.isCanadaUser;

  const propertiesRaw = useAtomValue(reportPropertiesAtom);
  const properties = propertiesRaw.map(prop => prop.property);

  const rateOfChangeData = useSelector(
    ({ report }: any) => report.reportDetailed.graphs.rate_of_change,
  );
  const sellingPaceData = useSelector(
    ({ report }: any) => report.reportDetailed.graphs.selling_pace,
  );
  const competitionData = useSelector(
    ({ report }: any) => report.reportDetailed.graphs.competition,
  );
  const { options } = usePricingFavorabilityData();

  const isDisabled = useMemo(
    () => options.every((option: any) => !option.price && !option.sale_price),
    [options],
  );

  const shelfLifeValue = getShelflifeAverage(properties);

  const isShelfLifeDisabled = !shelfLifeValue || shelfLifeValue == 0;

  const propertiesList = properties.filter(property => !!property.size);

  const PricePerSQFTGauge =
    isCanadaUser || !propertiesList.length ? null : (
      <PricePerSquareFootPdf key={'pricePerSquareFoot'} />
    );

  const PricingRateOfChangeGauge = !rateOfChangeData?.props?.length ? null : (
    <PricingRateOfChangePdf key={'pricingRateOfChange'} />
  );

  const SellingPaceGauge = !sellingPaceData?.graph?.length ? null : (
    <SellingPacePdf key={'sellingPace'} />
  );

  const ActiveCompetitionGauge = !competitionData.count ? null : (
    <ActiveCompetitionPdf key={'activeCompetition'} />
  );

  const PricingFavorabilityGauge = isDisabled ? null : (
    <PricingFavorabilityPdf key={'pricingFavorability'} />
  );

  const ActiveShelfGauge = isShelfLifeDisabled ? null : (
    <ActiveShelfLifePdf key={'activeShelfLife'} />
  );

  const marketOverview = [
    PricePerSQFTGauge,
    PricingRateOfChangeGauge,
    SellingPaceGauge,
    ActiveCompetitionGauge,
    PricingFavorabilityGauge,
    ActiveShelfGauge,
  ];

  return (marketOverview?.filter(slide => !!slide) as React.ReactElement[]) || [];
};
