import { useSelector } from 'react-redux';

export const useAveragePricePerSquareFeet = () => {
  const data = useSelector(({ report }) => report.reportDetailed.properties);

  const calculateAvgPricePerSqft = () => {
    const all = [...data.active, ...data.pending, ...data.sold];
    let sum = 0;
    all.forEach(property => {
      sum = sum + property.pricePerSqft;
    });
    return Math.round(sum / all.length);
  };

  return calculateAvgPricePerSqft();
};
