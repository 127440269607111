import React from 'react';
import styled from 'styled-components';

import { MainConfigSection } from './sections/MainConfigSection';
import { MainLayout } from '../../layouts/MainLayout/MainLayout';
import PastPresentationSection from './sections/PastPresentationSection';
import HomeHeaderSection from './components/HomeHeader/HomeHeaderSection';
import { AnalyticsSummaryBar } from './components/AnalyticsSummary/AnalyticsSummaryBar';
import { CreatePresentationActionsSection } from './sections/CreatePresentationActionsSection';
import { PastPresentationsSection } from './components/PastPresentation/PastPresentationsSection';

const BottomSection = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 30px;

  @media all and (max-width: 1550px) {
    position: relative;
    overflow: hidden;
  }

  @media all and (max-width: 998px) {
    padding: 20px 15px;
  }
`;

const BottomSectionWrapper = styled.div`
  width: 100%;
  max-width: 1550px;
  margin: 0 auto;
`;

export const HomePage: React.FC = () => {
  console.log('running version:' + process.env.REACT_APP_TAG);

  return (
    <MainLayout>
      <MainConfigSection />
      <CreatePresentationActionsSection />
      <PastPresentationSection />
    </MainLayout>
  );
};

export const Homepage: React.FC = () => {
  console.log('running version:' + process.env.REACT_APP_TAG);
  return (
    <MainLayout isWhiteBg>
      <HomeHeaderSection />
      <BottomSection>
        <BottomSectionWrapper>
          <AnalyticsSummaryBar />
          <PastPresentationsSection />
        </BottomSectionWrapper>
      </BottomSection>
    </MainLayout>
  );
};
