import { TSection, TSectionsSlide, TSlide, TSlideId } from '../../../types';

export class LibrarySlidesExtractor {
  private static slidesToExclude = { closingAndNextSteps: true } as Partial<
    Record<TSlideId | string, boolean>
  >;
  private static wtrSlidesToExclude = {
    purposeOfBuyersAgent: true,
    buyersAgentCompensation: true,
    whyIAmTheRightBuyersAgent: true,
    closingAndNextSteps: true,
  } as Partial<Record<TSlideId | string, boolean>>;

  private static filterSlides = (slides: TSlide[], excludeWTRSlides?: boolean) => {
    return slides.filter(slide =>
      excludeWTRSlides
        ? !LibrarySlidesExtractor.wtrSlidesToExclude[slide.id]
        : !LibrarySlidesExtractor.slidesToExclude[slide.id],
    );
  };

  static getLibrarySectionSlides(
    sectionsSlide: TSectionsSlide,
    slides: Partial<Record<TSlideId, TSlide>>,
    section: TSection,
  ): TSlide[] {
    if (!sectionsSlide[section]) return [];
    return sectionsSlide[section]
      .map(slideId => slides[slideId] as TSlide)
      .filter(slide => !!slide && !LibrarySlidesExtractor.slidesToExclude[slide.id])
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }

  static getLibrarySlides(
    slidesMap: Partial<Record<TSlideId, TSlide>>,
    excludeWTRSlides?: boolean,
  ): TSlide[] {
    const slides = Object.values(slidesMap);

    return LibrarySlidesExtractor.filterSlides(slides, excludeWTRSlides);
  }

  static getLibrarySlidesMap(
    slidesMap: Partial<Record<TSlideId, TSlide>>,
    excludeWTRSlides?: boolean,
  ) {
    const librarySlides = LibrarySlidesExtractor.getLibrarySlides(slidesMap, excludeWTRSlides);

    return librarySlides.reduce((map, slide) => ({ ...map, [slide.id]: { ...slide } }), {});
  }

  static getLibrarySections(
    sections: TSection[],
    sectionSlides: TSectionsSlide,
    slidesMap: Partial<Record<TSlideId, TSlide>>,
  ): TSection[] {
    if (!sectionSlides) return sections;

    return sections.filter(section => sectionSlides[section].some(slideId => slidesMap[slideId]));
  }
}
