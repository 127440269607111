import styled, { DefaultTheme } from 'styled-components';
import { Typography, Button } from '../../../../../../components/Common';
export { Typography, Button };

const tableConfig = (theme: DefaultTheme) => ({
  '&:nth-of-type(1)': {
    flexBasis: '20%',
  },
  '&:nth-of-type(2)': {
    flexBasis: '7.5%',
  },
  '&:nth-of-type(3)': {
    flexBasis: '10%',
    justifyContent: 'center',
  },
  '&:nth-of-type(4)': {
    flexBasis: '7.5%',
  },
  '&:nth-of-type(5)': {
    flexBasis: '10%',
  },
  '&:nth-of-type(6)': {
    flexBasis: '15%',
  },
  '&:nth-of-type(7)': {
    flexBasis: '30%',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    display: 'flex',
    '& > *': {
      ':nth-of-type(1)': {
        width: 116,
      },
      ':nth-of-type(2)': {
        display: 'flex',
        justifyContent: 'center',
        width: 90,
        margin: '0 24px',
      },
      ':nth-of-type(3)': {
        width: 108,
      },
    },
  },
});

export const TH = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...tableConfig(theme),
}));

export const TD = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...tableConfig(theme),
}));
