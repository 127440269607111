import { IconName } from '../../../../components/Common/Icon/Icon.types';

interface ItemProps {
  title: string;
  subtitle: string;
  icon: IconName;
  progress?: number;
  url: string;
  externalLink?: boolean;
}

export const item_1: ItemProps = {
  title: 'Customization Settings',
  subtitle: 'Personalize your “About Me” section',
  icon: 'settings',
  progress: 40,
  url: '/settings/customization',
};

export const item_2: ItemProps = {
  title: 'Presentation Library',
  subtitle: 'Add your content to the library',
  icon: 'presentation_library',
  url: '/library',
};

export const item_3: ItemProps = {
  title: 'Tracking Result',
  subtitle: 'Susan Smith viewed report 9/12',
  icon: 'tracking_results',
  url: '/',
};

export const item_4: ItemProps = {
  title: 'Help/FAQs',
  subtitle: 'Check out our Help Desk',
  icon: 'help',
  url: 'https://help.insiderealestate.com/en/collections/2474292-core-present-dashcma',
  externalLink: true,
};
