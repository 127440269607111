import { useSelector } from 'react-redux';

import {
  InfoCard,
  InfoCardTypes,
} from '../../../../view/pages/Authenticated/CompResults/CompsPerformance/InfoCard/InfoCard';
import { useUiConfigColorsPrimary } from '../../../../../../../providers/providers/UiConfigColorProvider';
import { useSellingPaceTookTimeMessage } from './useSellingPaceTookTimeMessage';
import styles from '../CommonStyles.module.scss';
import SellingPaceCardContent from '../../../../../../../components/Common/SellingPaceCardContent/SellingPaceCardContent';

export const SellingPaceGauge = ({ onClick = f => f }) => {
  const data = useSelector(({ report }) => report.reportDetailed.graphs.selling_pace);
  const primaryColor = useUiConfigColorsPrimary();

  const description = useSellingPaceTookTimeMessage(data);

  return (
    <InfoCard
      headline='Selling Pace'
      type={InfoCardTypes.CUSTOM}
      content={_ => <SellingPaceCardContent data={data} color={primaryColor} />}
      background='/present/assets/images/dashboard/homeinfo/selling-bg.png'
      description={description}
      info='Comparables are tracked over time to compare whether the “days on market” counts are trending up or down.'
      clicked={onClick}
      borderedCircleColor={primaryColor}
      className={styles.card}
      disabled={!data?.graph?.length}
    />
  );
};
