import React from 'react';
import create_presentation_png from './create_presentation.png';
import { ReactComponent as Logo } from '../../../../assets/images/present_logo.svg';
import {
  MainSectionContainer,
  Wrapper,
  Description,
  Title,
  Icon,
  PresentationImage,
  ImageWrapper,
  Text,
} from './MainSection.styles';
import { FEATURES, useHasFeatureFlag } from '../../../../hooks/useFeatureFlags';

export const MainSection: React.FC = () => {
  const shouldUseBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  return (
    <MainSectionContainer>
      <Wrapper>
        {shouldUseBtTheme ? (
          <Logo style={{ width: 227, marginTop: '30px' }} />
        ) : (
          <Icon iconName='logo' style={{ width: 227, marginTop: '30px' }} />
        )}

        <Description>
          <Title variant='h3' color='#444'>
            Create stunning presentations
          </Title>
          <Text variant='body1' color='#444' style={{ fontWeight: 400, marginTop: 6 }}>
            The next-generation CMA and presentation builder, CORE Present, helps you guide clients
            through an interactive and compelling pricing discussion. Create an engaging CMA in
            under a minute or build a fully customized, branded presentation with real-time activity
            tracking to ensure you win the deal.
          </Text>
        </Description>
      </Wrapper>
      <ImageWrapper>
        <PresentationImage src={create_presentation_png} alt='presentation illustration' />
      </ImageWrapper>
    </MainSectionContainer>
  );
};
