import React, { useState, useEffect } from 'react';

import { millisToHMS } from '../.././services/millisToHMS';

import { PropsContent } from './types';
import { TPresentation } from '../../../../../../../../types';

import { InnerContent, Wrapper, SelectorContainer } from './AnalyticsModalContent.styles';

import { TPresentationSession } from '../../../../../../../../api/TrackingApi';

import { useLoadPresentationAnalytics } from '../.././hooks/useLoadPresentationAnalytics';
import { useSetPresentationToState } from '../../../../../../../../features/report/hooks/useSetPresentationToState';
import { useConfigurePresentationAnalyticsProviders } from '../.././hooks/useConfigurePresentationAnalyticsProviders';

import { Cards } from '../.././components/Cards/Cards';
import { Layout } from '../.././components/Layout/Layout';
import { FollowUp } from '../.././components/FollowUp/FollowUp';
import Loader from '../../../../../../../../components/Loader/Loader';
import { AnalyticsView } from '../.././components/AnalyticsView/AnalyticsView';
import { SessionSelector } from '../.././components/SessionSelector/SessionSelector';

export const AnalyticsModalContent: React.FC<PropsContent> = ({ presentation }) => {
  const [activeSession, setActiveSession] = useState<TPresentationSession | undefined>(undefined);

  useConfigurePresentationAnalyticsProviders(presentation as TPresentation);

  useSetPresentationToState(presentation);

  const { data: sessions, isLoading } = useLoadPresentationAnalytics(presentation.hash);

  useEffect(
    function onLoadSessions() {
      if (activeSession || !sessions?.length || isLoading) return;

      const lastIndex = sessions?.length - 1 ?? 0;
      const lastSession = sessions[lastIndex] ?? null;

      setActiveSession(lastSession);
    },
    [sessions, isLoading, activeSession],
  );

  if (isLoading) {
    return (
      <Wrapper>
        <Loader size='4x' centered />
      </Wrapper>
    );
  }

  if (!activeSession) {
    return (
      <Wrapper>
        <h3>There are no sessions for this presentation!</h3>
      </Wrapper>
    );
  }

  const slidesViewed = Object.values(activeSession.slides ?? {})
    .filter((s: any) => s.views)
    .map((s: any) => s.id);

  return (
    <Layout>
      <InnerContent id='analytics-container'>
        <SelectorContainer>
          <SessionSelector
            sessions={sessions}
            activeSession={activeSession}
            setActiveSession={setActiveSession}
          />
        </SelectorContainer>

        <Cards
          totalSlides={activeSession.totalSlides}
          avgTimeSpent={millisToHMS(activeSession.avgSlidesDuration)}
          slidesViewed={slidesViewed}
          lastOpenedDate={activeSession?.startedAt ?? ''}
        />

        <AnalyticsView
          session={activeSession}
          hash={presentation.hash!}
          type={presentation.type!}
        />
      </InnerContent>

      <FollowUp followUp={activeSession.followUp} presentation={presentation} />
    </Layout>
  );
};
