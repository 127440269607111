import styled from 'styled-components';
import { TAnalyticsSummaryData } from './AnalyticsSummary.component';
import { useAnalyticsSummaryData } from './useAnalyticsSummaryData';

const Container = styled.div`
  width: 100%;
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  font-family: Figtree;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);

  @media all and (max-width: 1112px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media all and (max-width: 1112px) {
    width: 100%;
    flex-wrap: wrap;
  }

  @media all and (max-width: 951px) {
    justify-content: center;
  }

  @media all and (max-width: 397px) {
    flex-direction: column;
  }
`;

const TextBold = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[400]};
  text-wrap: nowrap;
`;

const Label = styled.p`
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  color: #000;
  margin-top: 5px;
  text-wrap: nowrap;
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
  min-width: 98px;

  &:last-of-type {
    border-right: none;
  }

  &:nth-of-type(4),
  &:nth-of-type(5) {
    min-width: 250px;
  }

  @media all and (max-width: 1434px) {
    padding: 0 20px;
  }

  @media all and (max-width: 1218px) {
    padding: 0 10px;
  }

  @media all and (max-width: 1112px) {
    flex: 1;
    margin-top: 34px;
  }

  @media all and (max-width: 895px) {
    &:nth-of-type(4) {
      border-right: none;
    }
  }

  @media all and (max-width: 645px) {
    &:nth-of-type(3) {
      border-right: none;
    }

    &:nth-of-type(4) {
      border-right: ${({ theme }) => `1px solid ${theme.colors.gray[200]}`};
    }
  }

  @media all and (max-width: 601px) {
    &:nth-of-type(3),
    &:nth-of-type(4) {
      border-right: none;
    }

    &:nth-of-type(4) {
      position: relative;
    }

    &:nth-of-type(4):after {
      content: '';
      display: block;
      width: 38px;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray[200]};
      position: absolute;
      bottom: -18px;
    }
  }

  @media all and (max-width: 397px) {
    border-right: none;
    position: relative;

    &:not(:last-of-type):after {
      content: '';
      display: block;
      width: 38px;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.gray[200]};
      position: absolute;
      bottom: -18px;
    }
  }
`;

export const AnalyticsSummaryBar = () => {
  const { analyticsSummaryData, isLoading } = useAnalyticsSummaryData();

  if (!analyticsSummaryData || isLoading) return null;

  return (
    <Container>
      <TextBold>Presentation Trends</TextBold>
      <ItemsContainer>
        {analyticsSummaryData?.map(summaryData => (
          <SummaryItem key={summaryData.label}>
            <TextBold>{summaryData.value ? summaryData.value : 'N/A'}</TextBold>
            <Label>{summaryData.label}</Label>
          </SummaryItem>
        ))}
      </ItemsContainer>
    </Container>
  );
};
