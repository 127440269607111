import styled from 'styled-components';

interface WithBackgroundProp {
  background?: 'primary' | 'default';
  column?: boolean;
}

export const Section = styled('section')<WithBackgroundProp>(({ theme, background, column }) => ({
  display: 'flex',
  flexDirection: column ? 'column' : 'row',
  padding: '3% 4%',
  position: 'relative',
  backgroundColor: background === 'primary' ? theme.colors.primary.main : theme.colors.gray[100],
  maxWidth: '100%',
}));

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  height: 250,
  '@media (max-width: 1077px)': {
    flexDirection: 'column',
    height: 'auto',
  },
}));

export const PresentationActionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
}));

export const ActionButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  zIndex: 2,
  '@media (max-width: 807px)': {
    flexWrap: 'wrap',
  },
}));
