import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { reportPropertiesAtom } from '../state/reportPropertiesAtom';
import { extractPropertyGroups } from '../services/extractPropertiesGroups';
import { useHighlightedProperties } from '../../../pages/PresentationCreate/dash/ReportFacade/data/useHighlightedProperties';
import { useIsDTC } from '../../../hooks/daysOnMarket/useIsDTC';
import { splitOnPages } from './services/splitOnPages';
import { TablePDFPageData, TablePDFPropertiesPage, TablePropertyStatus } from '../../../types';
import { propertiesToRows } from './services/propertiesToRows';
import { getTableColumns, getTableTitle } from './services/tableConfig';
import { DetailsTable } from './details-table/DetailsTable';
import { Title } from './Title';
import { PageBreak } from './PageBreak';
import { useColorBasedOnStatus } from '../../../hooks/useColorBasedOnStatus';

const getTableBreak = (page: TablePDFPageData, nextPage: TablePDFPageData) => {
  if (!page || !nextPage) {
    return false;
  }

  const [lastTableTypeOnPage] = Object.keys(page).reverse();
  const [firstTableTypeOnNextPage] = Object.keys(nextPage);

  return (lastTableTypeOnPage as TablePropertyStatus) ===
    (firstTableTypeOnNextPage as TablePropertyStatus)
    ? (lastTableTypeOnPage as TablePropertyStatus)
    : false;
};

export const usePropertiesTablesPdfPages = () => {
  const reportProperties = useAtomValue(reportPropertiesAtom);
  const properties = useMemo(() => extractPropertyGroups(reportProperties), [reportProperties]);

  const highlighted = useHighlightedProperties();
  const isDTC = useIsDTC();

  const [pagesData, setPagesData] = useState<TablePDFPropertiesPage>();
  const getColorBasedOnStatus = useColorBasedOnStatus();

  useEffect(() => {
    const data = splitOnPages(properties, isDTC);

    if (!Object.keys(data).length) {
      return;
    }

    setPagesData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!pagesData || !Object.keys(pagesData)) {
    return null;
  }

  const pages: ReactNode[] = [];

  Object.keys(pagesData).forEach(pageNumber => {
    const types = Object.keys(pagesData?.[Number(pageNumber)]) as TablePropertyStatus[];

    const page = types.map((type, i) => {
      const rows = propertiesToRows(
        (pagesData[Number(pageNumber)] as TablePDFPageData)[type].rows,
        type,
        highlighted,
        isDTC,
      );
      const average = (pagesData[Number(pageNumber)] as TablePDFPageData)[type].average
        ? (pagesData[Number(pageNumber)] as TablePDFPageData)[type].average
        : {};

      return (
        <DetailsTable
          key={i}
          title={getTableTitle(type)}
          columns={getTableColumns(type, isDTC)}
          data={rows}
          customColor={getColorBasedOnStatus(type)}
          dataAvg={average}
        />
      );
    });

    const tableBreak = getTableBreak(
      pagesData[Number(pageNumber)] as TablePDFPageData,
      pagesData[1 + Math.floor(Number(pageNumber))] as TablePDFPageData,
    );

    pages.push(
      <>
        {!pages.length && <Title title='Comparable Property Details' />}
        {page}

        {tableBreak && <PageBreak type={getTableTitle(tableBreak)} />}
      </>,
    );
  });

  return pages;
};
