import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { TableHeadButton } from '../../../../../../../components/Common/TableHeadButton/TableHeadButton.component';
import { PastPresentationsUtils } from '../../../services/PastPresentationsUtils';
import { PastPresentationItem, TPastPresentationSortOption } from '../../../types';
import { Typography, TH } from '../PastPresentationTable.styles';

interface Props {
  setPresentations: Dispatch<SetStateAction<PastPresentationItem[][]>>;
}

const TableHead = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 52,
  backgroundColor: theme.colors.gray[100],
  padding: theme.spacing(0, 3),
  minWidth: 1193,
}));

const TRHead = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  paddingLeft: 67.5,
  minWidth: 1140,
}));

export const PastPresentationHeadComponent: React.FC<Props> = ({ setPresentations }) => {
  const [sort, setSort] = useState<TPastPresentationSortOption>('lastUpdated_dsc');

  const onAddressClick = () => {
    const nextVal = sort === 'address_dsc' ? 'address_asc' : 'address_dsc';
    setSort(nextVal);
  };
  const onClientClick = () => {
    const nextVal = sort === 'client_dsc' ? 'client_asc' : 'client_dsc';
    setSort(nextVal);
  };
  const onLastUpdatedClick = () => {
    const nextVal = sort === 'lastUpdated_asc' ? 'lastUpdated_dsc' : 'lastUpdated_asc';
    setSort(nextVal);
  };

  const onFeedbackClick = () => {
    const nextVal = sort === 'topFeedback_asc' ? 'topFeedback_dsc' : 'topFeedback_asc';
    setSort(nextVal);
  };

  useEffect(() => {
    setPresentations(prev => PastPresentationsUtils.sort(prev, sort));
  }, [setPresentations, sort]);

  return (
    <TableHead>
      <TRHead>
        <TH>
          <TableHeadButton text='Address' onClick={onAddressClick} />
        </TH>
        <TH>
          <TableHeadButton text='Client' onClick={onClientClick} />
        </TH>
        <TH>
          <Typography variant='body2' color='400'>
            Versions
          </Typography>
        </TH>
        <TH>
          <TableHeadButton text='Last Updated' onClick={onLastUpdatedClick} />
        </TH>
        <TH />
        <TH>
          <TableHeadButton text='Feedback' onClick={onFeedbackClick} />
        </TH>
        <TH>
          <Typography variant='body2' color='400'>
            Presentation
          </Typography>
          <Typography variant='body2' color='400'>
            Analytics
          </Typography>
          <Typography variant='body2' color='400'>
            Actions
          </Typography>
        </TH>
      </TRHead>
    </TableHead>
  );
};
