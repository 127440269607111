import { MoneyFormatter } from '../../../../pages/PresentationCreate/dash/services/moneyFormatter';
import { NumberFormatter } from '../../../../pages/PresentationCreate/dash/services/numberFormatter';
import { getPropertyStatus } from '../../../../pages/PresentationCreate/dash/view/services/getPropertyStatus';
import {
  PROPERTY_STATUS,
  TProperty,
  TPropertyRowPdf,
  TablePropertyStatus,
} from '../../../../types';
import { getPropertyId } from '../../services/getPropertyId';

const isHighlighted = (property: TProperty, highlights: TProperty[]) =>
  highlights.some(highlight => getPropertyId(highlight) === getPropertyId(property));

const getStatusIndicator = (status: PROPERTY_STATUS | 'coming_soon') => getPropertyStatus(status);

function getDOM(property: TProperty, type: TablePropertyStatus, isDTC: boolean) {
  return isDTC && type === 'sold' ? property.daysToClose : property.daysOnMarket;
}

function getPricePerSq(property: TProperty, type: TablePropertyStatus) {
  if (!property.size) return 'n/a';

  const price = type === 'sold' ? property.salePrice : property.price;
  if (!price) return 'n/a';

  return MoneyFormatter.format(price / property.size);
}

export const propertiesToRows = (
  properties: TProperty[],
  type: TablePropertyStatus,
  highlighted: TProperty[],
  isDTC: boolean,
): TPropertyRowPdf[] => {
  if (!properties) {
    return [];
  }

  return properties
    .filter(p => p.excluded === false)
    .map(property => ({
      id: getPropertyId(property),
      address: property.address.deliveryLine,
      highlighted: isHighlighted(property, highlighted),
      status: getStatusIndicator(property.status),
      price: MoneyFormatter.format(property.price),
      salePrice: type !== 'sold' ? 'N/A' : MoneyFormatter.format(property.salePrice),
      bd: property.beds,
      bth: property.totalBaths,
      sqft: property.size ? NumberFormatter.format(property.size) : 'n/a',
      pricePerSq: getPricePerSq(property, type),
      built: property.yearBuilt || 'n/a',
      listDate: property.status === 'sold' ? property.saleDateHuman : property.listDateHuman,
      cdom: getDOM(property, type, isDTC),
      adjustedPrice: property.adjustedPrice ? MoneyFormatter.format(property.adjustedPrice) : null,
    }));
};
