import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../../../components/Loader/Loader';
import { CATEGORIES } from '../../../constants/categories';
import { PRESENTATION_TYPES } from '../../../constants/PresentationTypes';
import { withProviders } from '../../../hoc/withProviders';
import { CategoryProvider } from '../../../providers/providers/CategoryProvider';
import { PresentationTypeProvider } from '../../../providers/providers/PresentationTypeProvider';
import { CoreSlidesConfigProvider } from '../../../providers/providers/CoreSlidesConfigProvider';
import { useWTRPresentationCreator } from './useWTRPresentationCreator';

export const WTRCreator: React.FC = withProviders(
  [PresentationTypeProvider, { initValue: PRESENTATION_TYPES.PRESENT_INFO }],
  [CategoryProvider, { initValue: CATEGORIES.TRADITIONAL }],
  CoreSlidesConfigProvider,
)(() => {
  const { push } = useHistory();

  const { isLoading, isCreating, create } = useWTRPresentationCreator();

  useEffect(
    function onConfigsLoaded() {
      async function createWTRPresentation() {
        if (isLoading || isCreating) return;

        const res = await create();

        if (!res?.hash) return;
        push(`/presentation/${res.hash}/edit`);
      }

      createWTRPresentation();
    },
    [create, push, isCreating, isLoading],
  );

  return (
    <div style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0 }}>
      <Loader size='5x' centered />;
    </div>
  );
});
