import { useAuthMe } from '../useAuthMe';
import { AuthApi } from '../../api/AuthApi';
import { usePresentation } from '../usePresentation';
import { usePresentationHashFromUrl } from '../../pages/Presentation/hooks/usePresentationHashFromUrl';
import { useUser } from '../../components/Slide/providers/UserProvider';
import { useLocation } from 'react-router-dom';
import { URLS } from '../../pages/PresentationCreate/dash/view/constants/URLS';

export function useFeatureFlagIds() {
  const isAuth = AuthApi.isLoggedIn();
  const { data: apiUser } = useAuthMe(isAuth);

  const location = useLocation();

  const user = useUser();

  const omitGlobalStoreSet =
    location.pathname === URLS.RESULT || location.pathname === URLS.SEARCH.DETAILS;

  const hash = usePresentationHashFromUrl();
  const { data: presentation } = usePresentation(hash, { omitGlobalStoreSet });

  const presentationUser = presentation?.user as any;
  const accountId =
    presentationUser?.kvcore_account_id || apiUser?.kvcore_account_id || user?.accountId || 0;
  const kvCoreUserId =
    presentationUser?.kvcore_user_id || apiUser?.kvcore_user_id || user?.kvcoreUserId || 0;

  return { accountId, kvCoreUserId };
}
