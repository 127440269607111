import styled from 'styled-components';

interface WithRef {
  ref?: React.RefObject<HTMLDivElement>;
}
export const ConfigurationContainer = styled('div')<WithRef>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  width: 365,
  backgroundColor: '#fff',
  borderRadius: 8,
  overflow: 'hidden',
  boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.05)',
  marginLeft: theme.spacing(5),
  '@media (max-width: 1077px)': {
    width: '100%',
    margin: theme.spacing(4, 0),
  },
}));

export const ItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 20px',
  cursor: 'pointer',
  transition: 'all .2s',
  backgroundColor: 'transparent',
  alignItems: 'center',
  height: 62.5,
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.colors.gray[200]}`,
  },
  '&:hover': { backgroundColor: theme.colors.gray[200] },
  '& > svg': {
    marginTop: 4,
  },
}));

export const ItemContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(2.5),
}));
