import React from 'react';
import { useSelector } from 'react-redux';
import { useAtomValue } from 'jotai';

import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { getWeeksOnMarket } from '../../../../../../services/getWeeksOnMarket';
import { InfoCard } from '../../../view/pages/Authenticated/CompResults/CompsPerformance/InfoCard/InfoCard';
import styles from './CommonStyles.module.scss';

export const CompetitionGauge = ({ onClick = f => f }) => {
  const color = useUiConfigColorsPrimary();
  const data = useSelector(({ report }) => report.reportDetailed.graphs.competition);
  const properties = useAtomValue(reportPropertiesAtom);

  const onMarket = properties.filter(p => p.status === 'active').map(prop => prop.property);
  const countOfFresh = onMarket.filter(p => p.daysOnMarket <= 84).length;
  const countOfStale = onMarket.filter(p => p.daysOnMarket > 84).length;
  const weeksOnMarket = getWeeksOnMarket(onMarket);

  return (
    <InfoCard
      headline='Fresh/Stale'
      content={_ => (!data.count ? 'N/A' : countOfFresh > countOfStale ? 'Fresh' : 'Stale')}
      textContent={true}
      description={`The average active comparable has been on market for ${weeksOnMarket} ${
        weeksOnMarket > 1 ? 'weeks' : 'week'
      }`}
      background='/present/assets/images/dashboard/homeinfo/competition-bg.png'
      info='The count tracks how many comparable listings are currently active on the market.'
      clicked={onClick}
      disabled={!data.count}
      borderedCircleColor={color}
      className={styles.card}
    />
  );
};
